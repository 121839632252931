import { Ability, AbilityBuilder } from "@casl/ability"

export const getAbilities = user => {
    const { can, rules } = new AbilityBuilder(Ability)

    if (user) {
        if (user.perfil === 'Operador') {
            can('view', 'Operador')
        } else if (user.perfil === 'Supervisor') {
            can('view', 'Supervisor')
        } else if (user.perfil === 'Admin') {
            can('view', 'Admin')
        }
    } else {
        can('view', 'Auth')
    }

    return rules
}

